import React, { useEffect, useState } from "react";
import { AboutWrapper } from "../pages/constants";
import { getDataFromSanityById, getImgUrl } from "../utils/getDataFromSanity";
export const StackImagesAbout = () => {
	const [aboutData, setAboutData] = useState();

	useEffect(() => {
		if (!aboutData) {
			getDataFromSanityById("home").then((data) => setAboutData(data[0]));
		}
	}, [aboutData]);

	if (!aboutData) return <React.Fragment />;
	return (
		<AboutWrapper>
			{aboutData.images && aboutData.images.length ? (
				<div className="right-images">
					{aboutData.images.map((img, i) => (
						<div
							className="img"
							id={`staggered-img-${i + 1}`}
							style={{
								backgroundImage: `url(${getImgUrl(
									img.asset
								).url()})`,
							}}
						/>
					))}
				</div>
			) : null}
			<div className="left-copy">
				{aboutData.about ? (
					<React.Fragment>
						<p
							dangerouslySetInnerHTML={{
								__html: aboutData.about,
							}}
						/>
					</React.Fragment>
				) : null}
			</div>
		</AboutWrapper>
	);
};
