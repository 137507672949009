import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import styled from "styled-components";
// import { useCallback } from "react";
import { BorderedButton } from "../pages/constants";
import { useMediaQuery } from "react-responsive";
import { debounce, scrollToTop } from "../utils/helpers";
// import { useNavigate } from "react-router-dom";

const Header = styled.header`
	position: fixed;
	width: ${(props) => (props.theme.isTabletOrMobile ? "95.3%" : "97.33%")};
	height: max-content;
	top: 0px;
	padding-top: 34px;
	background: linear-gradient(180deg, #23272d 0%, rgba(35, 39, 45, 0) 100%);
	backdrop-filter: blur(4px);
	z-index: 999;
	transition: top 0.6s;
`;

const NavItems = styled.nav`
	display: flex;
	align-items: center;
	right: 0 !important;
	left: 0;
	padding-bottom: 20px;
	justify-content: space-between;
	border-bottom: ${(props) => props.theme.divider};

	& a {
		position: relative;
		text-decoration: none;
		&.name-tag-short {
			margin: 0 auto;
			letter-spacing: -4px;
			padding: 12px 10px 2px 10px;
		}
		&.name-tag {
			letter-spacing: -4px;
			& #n {
				letter-spacing: -11px;
			}
		}
	}
	& .hamburger-btn {
		position: absolute;
		right: 0px;
	}
`;
new Date().getFullYear();

// const Menu = styled.div`
// 	width: 100vw;
// 	height: 100%;
// 	background-color: ${(props) => props.theme.secondary};
// 	position: fixed;
// 	top: 0;
// 	left: 0;
// 	transition: transform 0.8s cubic-bezier(0, 0.52, 0, 1);
// 	overflow: scroll;
// 	z-index: 1000;
// 	border: 15px solid ${(props) => props.theme.primary};
// 	box-sizing: border-box;

// 	&[class*="closed"] {
// 		transform: translate3d(0, 100vh, 0);
// 	}

// 	&[class*="active"] {
// 		transform: translate3d(0, 0, 0);
// 		overflow: hidden;
// 	}

// 	& .menu-inner {
// 		padding: 34px 20px;
// 		height: 100%;
// 		display: flex;
// 		align-items: center;
// 		text-align: start;
// 		justify-content: start;
// 		& .close-btn {
// 			position: absolute;
// 			right: 20px;
// 			top: 34px;
// 		}
// 		& ul#menu-options {
// 			li {
// 				cursor: pointer;
// 				margin: ${(props) =>
// 						props.theme.isTabletOrMobile ? "30px" : "0"}
// 					0;
// 			}
// 			& .nav-item {
// 				color: ${(props) => props.theme.primary};
// 				font-size: calc(112.5% + 9.5vw);
// 				font-weight: 600;
// 				text-decoration: none;
// 				letter-spacing: -0.022em;
// 				line-height: 1em;
// 				position: relative;
// 				z-index: 10;
// 				text-transform: uppercase;
// 				transition: 1s cubic-bezier(0.075, 0.82, 0.165, 1);
// 				&:hover:before {
// 					width: 100%;
// 				}
// 				&:before {
// 					content: "";
// 					position: absolute;
// 					height: 4px;
// 					bottom: ${(props) =>
// 						props.theme.isTabletOrMobile ? "7px" : "30px"};
// 					background-color: ${(props) => props.theme.primary};
// 					left: 0px;
// 					width: 0px;
// 					transition: 0.9s cubic-bezier(0.075, 0.82, 0.165, 1);
// 				}
// 			}

// 			& .social-wrapper {
// 				position: absolute;
// 				bottom: 50px;
// 				& .social-item {
// 					color: ${(props) => props.theme.primary};
// 					font-size: 40px;
// 					font-weight: 300;
// 					text-decoration: none;

// 					position: relative;
// 					z-index: 10;
// 					transition: 1s cubic-bezier(0.075, 0.82, 0.165, 1);

// 					&:hover {
// 						opacity: 50% !important;
// 					}
// 				}
// 			}
// 		}
// 	}
// `;

// const menuItem = [
// 	{ name: "Home", to: "/", id: 0 },
// 	{ name: "Me", to: "/me", id: 1 },
// ];

export const NavMenu = () => {
	const isTabletOrMobile = useMediaQuery({
		query: "(max-width: 1224px)",
	});

	// const [menuState, setMenuState] = useState("closed");
	// const [isClosing, setIsClosing] = useState(false);
	const [prevScrollPos, setPrevScrollPos] = useState(0);
	const [visible, setVisible] = useState(true);
	// const navigate = useNavigate();

	const handleScroll = debounce(() => {
		const currentScrollPos = window.pageYOffset;

		setVisible(
			(prevScrollPos > currentScrollPos &&
				prevScrollPos - currentScrollPos > 70) ||
				currentScrollPos < 10
		);

		setPrevScrollPos(currentScrollPos);
	}, 100);

	useEffect(() => {
		window.addEventListener("scroll", handleScroll);

		return () => window.removeEventListener("scroll", handleScroll);
	}, [prevScrollPos, visible, handleScroll]);

	// const closeMenu = useCallback(() => {
	// 	setIsClosing(true);
	// 	setTimeout(() => {
	// 		// setMenuState("closed");
	// 		setIsClosing(false);
	// 	}, 1000);
	// }, []);

	// const linkClickHandler = useCallback(
	// 	(to) => {
	// 		navigate(to, { replace: true });
	// 		closeMenu();
	// 	},
	// 	[closeMenu, navigate]
	// );

	// const container = {
	// 	close: {
	// 		transition: {
	// 			staggerChildren: 0.1,
	// 			staggerDirection: -1,
	// 		},
	// 	},
	// 	open: {
	// 		opacity: 1,
	// 		transition: {
	// 			staggerChildren: 0.3,
	// 		},
	// 	},
	// };
	// const listItem = {
	// 	close: { opacity: 0 },
	// 	open: { opacity: 1 },
	// };

	return (
		<React.Fragment>
			<Header style={{ top: visible ? "0" : "-117px" }}>
				<NavItems>
					<div onClick={scrollToTop}>
						<BorderedButton
							className={
								isTabletOrMobile ? "name-tag-short" : "name-tag"
							}
							overlap
						>
							{isTabletOrMobile ? (
								<span>AC</span>
							) : (
								<span>
									A<span id="n">n</span>drew Clark
								</span>
							)}
						</BorderedButton>
					</div>
					{/* {isTabletOrMobile ? (
						<Hamburger onClick={() => setMenuState("active")} />
					) : (
						<BorderedButton onClick={() => setMenuState("active")}>
							<span>MENU</span>
						</BorderedButton>
					)} */}
				</NavItems>
			</Header>
			{/* <Menu className={`menu-${menuState}`}>
				<div className="menu-inner">
					<BorderedButton
						className="close-btn"
						onClick={closeMenu}
						responsive
					>
						<span>close</span>
					</BorderedButton>
					{menuState === "active" && (
						<motion.ul
							id="menu-options"
							variants={container}
							initial="close"
							animate={!isClosing ? "open" : "close"}
						>
							{menuItem.map(({ name, to, id }) => {
								return (
									<motion.li
										key={id + "/"}
										variants={listItem}
										onClick={() => linkClickHandler(to)}
									>
										<span className="nav-item">{name}</span>
									</motion.li>
								);
							})}
							<div className="social-wrapper">
								<li>
									<motion.a
										href="https://www.instagram.com/apclark_"
										variants={listItem}
										className="social-item"
										target="_blank"
									>
										in. / apclark_
									</motion.a>
								</li>
								<li>
									<motion.a
										href="https://www.linkedin.com/in/aclark19"
										variants={listItem}
										className="social-item"
										target="_blank"
									>
										li. / aclark19
									</motion.a>
								</li>
								<li>
									<motion.a
										href="mailto:andrew@dev.apclark.org"
										variants={listItem}
										className="social-item"
										target="_blank"
									>
										andrew@dev.apclark.org
									</motion.a>
								</li>
							</div>
						</motion.ul>
					)}
				</div>
			</Menu> */}
		</React.Fragment>
	);
};
