import { useMediaQuery } from "react-responsive";
import React from "react";
import { getImgUrl } from "../utils/getDataFromSanity";
import { Gallery } from "react-grid-gallery";
import { delayClickHandler } from "../utils/helpers";
import styled from "styled-components";

const GalleryWrapper = styled.div`
	.ReactGridGallery_tile {
		background-color: transparent !important;
	}
	.ReactGridGallery > div {
		justify-content: space-between;
	}

	& .ReactGridGallery_tile-description {
		background: ${(props) => props.theme.secondary} !important;
	}
	& .gallery-img-caption {
		margin: 10px 0;
		background: ${(props) => props.theme.secondary} !important;
		height: 100%;
		& sub {
			margin-top: 7px;
			font-style: italic;
			font-size: 15px;
			opacity: 40%;
			text-transform: none;
		}
	}
`;

const ImgWrapper = styled.div`
	&.linked {
		position: relative;
		img[data-testid*="grid-gallery-item_thumbnail"] {
			/* position: absolute; */
			cursor: pointer !important;
			/* &:hover {
				bottom: 30px;
			} */
		}
	}
	& img {
		cursor: default !important;
	}
`;

export const CustomGallery = (props) => {
	const isTabletOrMobile = useMediaQuery({
		query: "(max-width: 1224px)",
	});
	if (!props.props) return;

	const imageData = props.props.value.images.map((img) => ({
		src: getImgUrl(img.asset).url(),
		alt: img.alt,
		thumbnailCaption: (
			<div className="gallery-img-caption">
				<sub>{img.caption}</sub>
			</div>
		),
		externalUrl: img.externalURL,
	}));

	const ImageComponent = (props) => {
		const hasLink = !!props.item.externalUrl;

		return (
			<ImgWrapper
				className={`gallery-img-wrapper ${hasLink ? "linked" : ""}`}
				onClick={() =>
					hasLink
						? delayClickHandler(props.item.externalUrl, false)
						: null
				}
			>
				<img {...props.imageProps} alt={props.item.alt ?? ""} />
				{props.item.caption && <sub>{props.item.caption}</sub>}
			</ImgWrapper>
		);
	};

	return (
		<GalleryWrapper>
			<Gallery
				margin={30}
				images={imageData}
				enableImageSelection={false}
				thumbnailImageComponent={ImageComponent}
				rowHeight={isTabletOrMobile ? 400 : 800}
			/>
		</GalleryWrapper>
	);
};
