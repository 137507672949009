export const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

// Adding delay so users can see my cool button animation 😎
export const delayClickHandler = async (link, target) => {
	await delay(500);
	window.open(link, target ?? "_blank");
};

export function debounce(func, wait, immediate) {
	var timeout;
	return function () {
		var context = this,
			args = arguments;
		var later = function () {
			timeout = null;
			if (!immediate) func.apply(context, args);
		};
		var callNow = immediate && !timeout;
		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
		if (callNow) func.apply(context, args);
	};
}

export const scrollToTop = () =>
	window.scrollTo({ top: 0, behavior: "smooth" });
