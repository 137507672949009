import React from "react";

export const SmartrrSpinner = (props) => {
	return (
		<svg
			width="118"
			height="118"
			viewBox="0 0 118 118"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M6.36811 57.2454C5.5665 57.1834 5.15533 56.586 5.25336 55.3177C5.34029 54.1931 5.83939 53.4975 6.83242 53.5743L6.91289 52.5334C5.41827 52.4058 4.45887 53.451 4.32292 55.2097C4.18697 56.9685 4.95776 58.2076 6.30973 58.3121C9.59991 58.5664 8.28658 53.6024 10.6077 53.7818C11.4222 53.8327 11.8333 54.5867 11.7426 55.7592C11.6354 57.147 11.0157 57.8454 10.0825 57.7732L10.002 58.8141C11.5574 58.9343 12.5205 57.8413 12.6694 55.9151C12.809 54.1085 12.018 52.8197 10.69 52.717C7.30407 52.4553 8.58151 57.4165 6.36811 57.2454Z"
				fill="#A26482"
			/>
			<path
				d="M6.35524 43.9742L11.2439 45.1998C12.7455 45.5763 13.3094 46.5342 12.9768 47.8611C12.6441 49.1881 11.6949 49.7666 10.1934 49.3901L5.30466 48.1645L5.04785 49.1888L9.93655 50.4144C11.9037 50.9076 13.3856 50.0791 13.8847 48.0887C14.3603 46.1915 13.4679 44.6687 11.5007 44.1755L6.61204 42.9499L6.35524 43.9742Z"
				fill="#A26482"
			/>
			<path
				d="M13.0486 37.3153L13.0267 37.3056C13.0546 36.8851 12.8349 36.0147 11.8027 35.5598C10.7266 35.0856 9.59591 35.479 9.0249 36.7748L7.71836 39.7397L15.2514 43.0593L16.5676 40.0724C17.187 38.6668 16.7773 37.4241 15.4925 36.8579C14.3944 36.374 13.5098 36.7448 13.0486 37.3153ZM11.5148 36.6C12.2066 36.9049 12.5547 37.5434 12.1772 38.4L11.3836 40.2009L8.98974 39.1459L9.78818 37.3341C10.1269 36.5654 10.7571 36.2661 11.5148 36.6ZM15.7233 39.7266L14.8426 41.7252L12.1742 40.5493L13.0259 38.6166C13.4082 37.7491 14.0846 37.4046 14.985 37.8014C15.8745 38.1934 16.0814 38.914 15.7233 39.7266Z"
				fill="#A26482"
			/>
			<path
				d="M13.5957 32.4559C12.9206 32.0194 12.8439 31.2981 13.5346 30.23C14.1471 29.2828 14.9174 28.9092 15.7538 29.45L16.3207 28.5733C15.0676 27.7487 13.726 28.2102 12.7681 29.6915C11.8103 31.1728 11.8973 32.6295 13.036 33.3658C15.8071 35.1577 17.0187 30.1679 18.9736 31.432C19.6653 31.865 19.6674 32.7237 19.0288 33.7112C18.273 34.8802 17.3954 35.1987 16.6094 34.6904L16.0425 35.5671C17.3525 36.4142 18.7201 35.9124 19.7692 34.29C20.7531 32.7684 20.672 31.2584 19.5535 30.5351C16.7018 28.6911 15.4599 33.6614 13.5957 32.4559Z"
				fill="#A26482"
			/>
			<path
				d="M23.943 27.2897C22.9296 28.3956 21.2835 28.4334 19.7618 27.039C18.2755 25.6769 18.1506 23.9837 19.1478 22.8955C20.1937 21.7542 21.3267 21.8322 22.2999 22.5287L23.0295 21.7324C21.5897 20.6571 19.8928 20.6972 18.4497 22.272C17.0147 23.8379 17.1647 26.1076 19.0402 27.8264C20.9778 29.6019 23.1656 29.5234 24.6249 27.9309C26.1005 26.3207 25.9439 24.6799 24.7901 23.3458L24.0604 24.142C24.8125 25.0265 24.9969 26.1396 23.943 27.2897Z"
				fill="#A26482"
			/>
			<path
				d="M32.5255 19.6337C32.4595 19.7448 32.343 19.8493 32.1812 19.9734C31.9527 20.1487 31.654 20.272 31.1451 20.0424L29.1547 19.1041C29.815 18.2496 29.9019 17.1998 29.1349 16.2001C28.1488 14.9148 26.7184 14.9233 25.4998 15.8583L23.1101 17.6917L28.121 24.2229L28.9588 23.5801L26.9428 20.9524L28.2471 19.9517L30.3553 20.9055C31.1801 21.2709 31.8516 21.4363 32.6799 20.8008C32.8798 20.6474 33.0293 20.4874 33.1546 20.3156L32.5255 19.6337ZM26.4095 20.2574L24.5104 17.782L26.0622 16.5914C26.9096 15.9413 27.7398 16.1362 28.2876 16.8502C28.8354 17.5643 28.8088 18.4166 27.9614 19.0667L26.4095 20.2574Z"
				fill="#A26482"
			/>
			<path
				d="M35.0376 19.2763L35.9527 18.7493L31.8443 11.6158L30.9292 12.1428L35.0376 19.2763Z"
				fill="#A26482"
			/>
			<path
				d="M40.4041 11.8013L40.3947 11.7793C40.7023 11.4912 41.1364 10.7054 40.6931 9.66816C40.2309 8.58678 39.1363 8.10194 37.8342 8.65841L34.8549 9.93169L38.09 17.5014L41.0914 16.2187C42.5038 15.615 43.0533 14.4275 42.5016 13.1365C42.03 12.033 41.1306 11.6997 40.4041 11.8013ZM39.7723 10.2313C40.0694 10.9265 39.8875 11.6306 39.0268 11.9985L37.2171 12.7719L36.1891 10.3664L38.0098 9.58824C38.7822 9.25813 39.4469 9.46994 39.7723 10.2313ZM40.7109 15.3895L38.7026 16.2478L37.5567 13.5664L39.4987 12.7364C40.3705 12.3638 41.0998 12.5741 41.4865 13.479C41.8685 14.3728 41.5275 15.0405 40.7109 15.3895Z"
				fill="#A26482"
			/>
			<path
				d="M45.7184 13.5635L45.0209 10.7816L48.9319 9.80104L48.7013 8.8815L44.7904 9.86204L44.1746 7.40605L48.458 6.33213L48.2275 5.41259L42.9198 6.74332L44.9217 14.7282L50.2876 13.3829L50.06 12.475L45.7184 13.5635Z"
				fill="#A26482"
			/>
			<path
				d="M55.5971 9.54903L63.7669 9.73827L63.7853 8.94648L55.6155 8.75724L55.5971 9.54903Z"
				fill="#A26482"
			/>
			<path
				d="M70.9801 13.2128L71.8034 10.4655L75.6657 11.6229L75.9378 10.7148L72.0755 9.55736L72.8024 7.13192L77.0325 8.39956L77.3046 7.49146L72.0629 5.92069L69.6999 13.8062L74.9991 15.3942L75.2677 14.4976L70.9801 13.2128Z"
				fill="#A26482"
			/>
			<path
				d="M85.2004 11.0689L82.2175 16.9295L81.3611 9.11477L80.3986 8.62487L76.6645 15.9612L77.6056 16.4402L80.594 10.569L81.4449 18.3944L82.4074 18.8843L86.1415 11.5479L85.2004 11.0689Z"
				fill="#A26482"
			/>
			<path
				d="M90.0764 18.9906L89.5182 19.6966L91.5515 21.3042L91.4026 21.4925C90.6332 22.2915 89.179 22.6409 87.8705 21.6064C86.6562 20.6463 86.5251 19.0128 87.8275 17.3655C89.0184 15.8594 90.6901 15.5444 92.008 16.5863C93.2129 17.539 93.1779 18.6281 92.6954 19.4704L93.5426 20.1403C94.3784 18.8123 94.2452 17.162 92.5791 15.8447C90.7718 14.4157 88.5155 14.7734 86.9898 16.7031C85.345 18.7834 85.5486 20.9637 87.2806 22.3332C88.3725 23.1965 89.4561 23.2578 90.3854 22.8758L89.4997 23.9959L90.1398 24.502L92.7968 21.1415L90.0764 18.9906Z"
				fill="#A26482"
			/>
			<path
				d="M95.444 30.1843L96.1512 30.9847L100.31 23.2591L99.5078 22.3508L91.3284 25.526L92.0356 26.3263L94.1009 25.5104L96.5083 28.2353L95.444 30.1843ZM95.0327 25.1515L99.0317 23.5881L96.9793 27.3548L95.0327 25.1515Z"
				fill="#A26482"
			/>
			<path
				d="M102.108 32.3083L101.34 32.7774L102.691 34.9895L102.486 35.1146C101.487 35.5979 100.002 35.4223 99.1328 33.9988C98.3259 32.6777 98.7683 31.0998 100.56 30.0052C102.199 29.0044 103.876 29.2876 104.752 30.7213C105.553 32.0322 105.143 33.0418 104.399 33.6651L104.962 34.5867C106.205 33.6302 106.652 32.0359 105.545 30.2232C104.344 28.2569 102.103 27.8115 100.004 29.0938C97.7405 30.4761 97.1768 32.5921 98.3277 34.4764C99.0533 35.6644 100.049 36.097 101.053 36.0602L99.834 36.8046L100.259 37.5009L103.915 35.268L102.108 32.3083Z"
				fill="#A26482"
			/>
			<path
				d="M102.631 40.44L105.308 39.4124L106.753 43.1767L107.638 42.837L106.193 39.0727L108.557 38.1654L110.14 42.2882L111.025 41.9485L109.064 36.8399L101.379 39.7896L103.361 44.9542L104.235 44.6188L102.631 40.44Z"
				fill="#A26482"
			/>
			<path
				d="M107.777 49.4437L108.595 57.5747L109.383 57.4954L108.565 49.3644L107.777 49.4437Z"
				fill="#A26482"
			/>
			<path
				d="M105.156 70.5735C105.13 70.4469 105.146 70.2912 105.183 70.0906C105.235 69.8073 105.374 69.5155 105.905 69.3442L108.01 68.7045C108.094 69.7811 108.734 70.6178 109.974 70.8444C111.567 71.1357 112.622 70.1697 112.898 68.6588L113.44 65.6959L105.342 64.2154L105.152 65.2542L108.41 65.8498L108.115 67.467L105.911 68.1743C105.055 68.4569 104.446 68.7847 104.258 69.8117C104.213 70.0596 104.209 70.2785 104.232 70.49L105.156 70.5735ZM109.272 66.0074L112.341 66.5685L111.989 68.4926C111.797 69.5432 111.051 69.9557 110.166 69.7938C109.28 69.632 108.728 68.9821 108.92 67.9315L109.272 66.0074Z"
				fill="#A26482"
			/>
			<path
				d="M104.25 73.5227L106.946 74.5L105.573 78.2907L106.464 78.6137L107.838 74.823L110.218 75.6857L108.714 79.8375L109.605 80.1605L111.469 75.0159L103.73 72.211L101.845 77.412L102.725 77.7309L104.25 73.5227Z"
				fill="#A26482"
			/>
			<path
				d="M100.253 80.6242L99.7495 81.5526L106.153 85.0237L104.867 87.3974L105.7 87.8491L108.777 82.1733L107.943 81.7215L106.656 84.0953L100.253 80.6242Z"
				fill="#A26482"
			/>
			<path
				d="M94.7097 89.0145L94.0658 89.8665L102.464 92.4076L103.194 91.4406L98.4574 84.0553L97.8135 84.9074L99.0235 86.7694L96.8313 89.6702L94.7097 89.0145ZM99.5604 87.6113L101.887 91.2198L97.7879 89.9569L99.5604 87.6113Z"
				fill="#A26482"
			/>
			<path
				d="M93.2891 90.5655L92.5589 91.3283L98.5053 97.0209L99.2356 96.2581L93.2891 90.5655Z"
				fill="#A26482"
			/>
			<path
				d="M91.8383 102.955L87.7598 97.7967L95.2177 100.283L96.0648 99.6134L90.9592 93.156L90.1309 93.8109L94.2168 98.9787L86.7515 96.4828L85.9043 97.1527L91.01 103.61L91.8383 102.955Z"
				fill="#A26482"
			/>
			<path
				d="M81.9223 103.315L74.3377 106.357L74.6325 107.092L82.2172 104.05L81.9223 103.315Z"
				fill="#A26482"
			/>
			<path
				d="M64.0043 106.255C65.4967 106.105 66.7499 107.173 66.9565 109.227C67.1582 111.232 66.1238 112.579 64.6552 112.726C63.115 112.881 62.3216 112.069 62.0595 110.901L60.9849 111.009C61.3429 112.77 62.6355 113.87 64.7608 113.657C66.8741 113.444 68.2737 111.651 68.0191 109.12C67.7561 106.505 66.0717 105.106 63.9225 105.323C61.7495 105.541 60.7735 106.87 60.7459 108.633L61.8205 108.525C61.8485 107.364 62.4521 106.411 64.0043 106.255Z"
				fill="#A26482"
			/>
			<path
				d="M52.6338 113.539L52.9431 110.061L57.282 110.447L56.9727 113.925L58.0245 114.018L58.7537 105.819L57.7019 105.725L57.366 109.502L53.0271 109.117L53.363 105.339L52.3112 105.246L51.5819 113.446L52.6338 113.539Z"
				fill="#A26482"
			/>
			<path
				d="M44.3387 103.364L43.309 103.08L44.053 111.823L45.2216 112.144L50.3321 105.012L49.3024 104.729L48.0197 106.542L44.5139 105.577L44.3387 103.364ZM47.4359 107.352L44.9434 110.848L44.6012 106.572L47.4359 107.352Z"
				fill="#A26482"
			/>
			<path
				d="M33.4229 107.587L38.4971 102.337L37.4523 109.564L38.9607 110.304L42.5748 102.907L41.659 102.458L38.4569 108.987L39.4827 101.391L38.6316 100.973L33.2372 106.467L36.4552 99.9059L35.5394 99.4568L31.9037 106.842L33.4229 107.587Z"
				fill="#A26482"
			/>
			<path
				d="M26.089 102.897L28.5851 104.769L33.5242 98.1837L32.6794 97.5501L30.6346 100.277L28.9834 99.0382C27.7546 98.1166 26.3194 98.1502 25.3906 99.3886C24.4618 100.627 24.8602 101.976 26.089 102.897ZM28.429 99.7774L30.0802 101.016L28.2946 103.397L26.6434 102.158C25.7986 101.525 25.7626 100.673 26.2594 100.01C26.7562 99.3478 27.5842 99.1438 28.429 99.7774Z"
				fill="#A26482"
			/>
			<path
				d="M27.5077 93.3891L26.7449 92.6589L21.0522 98.6053L21.815 99.3355L27.5077 93.3891Z"
				fill="#A26482"
			/>
			<path
				d="M22.8227 88.2731C21.4384 86.5223 19.081 86.1527 17.0383 87.7678C14.9957 89.3828 14.868 91.7172 16.2523 93.4681C17.6367 95.2189 19.9941 95.5885 22.0367 93.9734C24.0794 92.3584 24.207 90.024 22.8227 88.2731ZM22.0885 88.8537C23.0858 90.115 22.9463 91.8928 21.3461 93.158C19.7553 94.4158 17.9839 94.1489 16.9866 92.8876C15.9892 91.6262 16.1381 89.841 17.729 88.5832C19.3292 87.318 21.0912 87.5923 22.0885 88.8537Z"
				fill="#A26482"
			/>
			<path
				d="M9.63567 82.8844L15.4851 79.8798L11.604 86.7165L12.0975 87.6771L19.42 83.9159L18.9375 82.9765L13.0774 85.9867L16.9691 79.1445L16.4757 78.1838L9.15317 81.9451L9.63567 82.8844Z"
				fill="#A26482"
			/>
			<path
				d="M11.1924 72.9769L9.69197 64.9438L8.91343 65.0892L10.4138 73.1223L11.1924 72.9769Z"
				fill="#A26482"
			/>
			<g clip-path="url(#clip0_1418_23703)">
				<path
					fill-rule="evenodd"
					clip-rule="evenodd"
					d="M60.5926 75.6528C61.4136 75.6528 62.1097 75.049 62.2257 74.2362L62.8384 69.9483C63.9974 69.5063 65.0669 68.883 66.0123 68.113L70.035 69.7277C70.7972 70.0335 71.6681 69.7325 72.0786 69.0214L73.9473 65.7843C74.3582 65.0732 74.1834 64.1684 73.5372 63.6614L70.1289 60.9859C70.2239 60.3889 70.2735 59.7766 70.2735 59.1528C70.2735 58.529 70.2239 57.9168 70.1289 57.3198L73.5372 54.6443C74.1834 54.1373 74.3582 53.2325 73.9473 52.5214L72.0786 49.2843C71.6681 48.5732 70.7972 48.2722 70.035 48.578L66.0123 50.1927C65.0669 49.4227 63.9974 48.7994 62.8384 48.3573L62.2257 44.0695C62.1097 43.2566 61.4136 42.6528 60.5926 42.6528L56.8543 42.6528C56.0334 42.6528 55.3373 43.2566 55.2213 44.0695L54.6085 48.3573C53.4496 48.7994 52.3801 49.4227 51.4346 50.1926L47.4124 48.578C46.6502 48.2722 45.7793 48.5732 45.3688 49.2843L43.4997 52.5214C43.0892 53.2325 43.264 54.1373 43.9097 54.6443L47.3181 57.3196C47.223 57.9167 47.1735 58.529 47.1735 59.1528C47.1735 59.3566 47.1787 59.5591 47.1892 59.7602C47.2105 60.175 47.254 60.5839 47.3181 60.986L43.9097 63.6614C43.264 64.1684 43.0892 65.0732 43.4997 65.7843L45.3688 69.0214C45.7793 69.7325 46.6502 70.0335 47.4124 69.7277L51.4346 68.1131C52.3801 68.883 53.4496 69.5063 54.6086 69.9483L55.2213 74.2362C55.3373 75.049 56.0334 75.6528 56.8543 75.6528L60.5926 75.6528ZM58.7233 54.2028C55.9895 54.2028 53.7733 56.419 53.7733 59.1528C53.7733 61.8866 55.9895 64.1028 58.7233 64.1028C61.4571 64.1028 63.6733 61.8866 63.6733 59.1528C63.6733 56.419 61.4571 54.2028 58.7233 54.2028Z"
					fill="#A26482"
				/>
			</g>
			<defs>
				<clipPath id="clip0_1418_23703">
					<rect
						width="33"
						height="33"
						fill="white"
						transform="translate(75.4879 75.6528) rotate(180)"
					/>
				</clipPath>
			</defs>
		</svg>
	);
};
