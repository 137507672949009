import React, { useState, useEffect } from "react";

import { HeroWrapper, AllWorkWrapper } from "../pages/constants";
import { ScrollingMarquee } from "./ScrollingMarquee";
import {
	getAllProjectDataFromSanity,
	getImgUrl,
} from "../utils/getDataFromSanity";

export default function Projects() {
	const [allWorkData, setAllWorkData] = useState([]);

	useEffect(() => {
		getAllProjectDataFromSanity().then((data) =>
			setAllWorkData(data.filter((e) => e.publish))
		);
	}, []);

	if (!allWorkData) return <React.Fragment />;

	const isEvenLength = allWorkData.length % 2 === 0;

	return (
		<React.Fragment>
			<HeroWrapper>
				<div className="header">
					<h1>Selected</h1>
					<h1>
						Work <span className="icon w-100">✺</span>
					</h1>
				</div>
				<div className="pushed-subtitle">
					<sub>2016 - {new Date().getFullYear()}</sub>
				</div>
			</HeroWrapper>

			<AllWorkWrapper>
				{allWorkData
					.sort(
						(a, b) =>
							new Date(a.dateStart).getTime() >
							new Date(b.dateStart).getTime()
					)
					.map((work, index) => (
						<React.Fragment>
							<SingleProjectRow
								key={index}
								work={work}
								index={index}
							/>
							{index ===
								(isEvenLength
									? Math.floor(allWorkData.length / 2) - 1
									: 1) && <ScrollingMarquee />}
						</React.Fragment>
					))}
			</AllWorkWrapper>
		</React.Fragment>
	);
}

const SingleProjectRow = (props) => {
	const { work, index } = props;

	return (
		<a
			href={work.url}
			target="_blank"
			className={`project-fw text-${index % 2 === 0 ? "left" : "right"}`}
			rel="noreferrer"
		>
			<div className="name-wrapper">
				{!!work.projectAttrs ? (
					<div className="attrs-wrapper">
						{work.projectAttrs.map((attrs) => (
							<sub>{attrs}</sub>
						))}
					</div>
				) : null}

				<h3>{work.projectName}</h3>
				<p dangerouslySetInnerHTML={{ __html: work.introText }} />
			</div>
			<div className="image-wrapper">
				{!!work.mainImage ? (
					<div
						style={{
							backgroundImage: `url(${getImgUrl(
								work.mainImage.asset._ref
							).url()})`,
						}}
						id={work.url}
						className={`lazyload project-img`}
						alt={work.workName}
					/>
				) : null}
			</div>
		</a>
	);
};
