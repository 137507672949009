import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { BorderedButton } from "../pages/constants";
import { getDataFromSanityById } from "../utils/getDataFromSanity";
import { GithubFill, InstagramFill, LinkedinBoxFill } from "akar-icons";

const Wrapper = styled.div.attrs((props) => ({
	className: "social-list",
}))`
	display: flex;
	flex-direction: ${(props) =>
		props.direction === "vertical" ? "column" : "row"};
	border-left: ${(props) =>
		props.direction === "vertical" && !props.buttons
			? props.theme.divider
			: "0"};
	width: 100%;
	padding: ${(props) => (props.direction === "vertical" ? "0px 10px" : "0")};
	text-align: left;
	justify-content: center;
	margin-top: ${(props) => (props.icons ? "20px" : "0")};
	gap: 20px;

	& caption {
		position: relative;
		text-align: left !important;
		transition: 0.6s;

		:before {
			${(props) =>
				props.direction !== "vertical"
					? `
			content: "";
			position: absolute;
			width: 4px;
			height: 100%;
			left: -10px;
			background: ${props.theme.primary};
			border-radius: 10px;
			top: -5px;
            `
					: null}
		}

		:hover a {
			left: 10px;
		}
		&:not(:first-child) {
			${(props) =>
				props.direction === "vertical"
					? `
            padding-top: 10px;
            `
					: `
            margin-left:80px;
            `}
		}
		& a {
			position: relative;
			color: ${(props) => props.theme.primary};
			text-decoration: none;
			border-color: none;
			left: 0px;
			transition: 0.9s cubic-bezier(0.075, 0.82, 0.165, 1);
		}
	}
`;

export const SocialList = (props) => {
	const [socialLinks, setSocialLinks] = useState();

	useEffect(() => {
		getDataFromSanityById("globalSettings").then((data) =>
			setSocialLinks(data[0].socialLinks)
		);
	}, []);

	function getIcon(name) {
		switch (name) {
			case "instagram":
				return <InstagramFill strokeWidth={2} size={36} />;
			case "github":
				return <GithubFill strokeWidth={2} size={36} />;
			case "linkedin":
				return <LinkedinBoxFill strokeWidth={2} size={36} />;
			case "resume":
				return <React.Fragment />;
			// return <File strokeWidth={2} size={36} />;
			default:
				return <React.Fragment />;
		}
	}

	if (!socialLinks) return;

	if (props.icons) {
		return (
			<Wrapper direction={props.direction} buttons={props.buttons} icons>
				{socialLinks.map((link) => {
					if (link.title.toLowerCase() === "resume")
						return <React.Fragment key={link.key} />;

					return (
						<a
							key={link.key}
							href={link.link}
							target="_blank"
							rel="noreferrer"
						>
							{getIcon(link.title.toLowerCase())}
						</a>
					);
				})}
			</Wrapper>
		);
	}

	if (props.buttons) {
		return (
			<Wrapper direction={props.direction} buttons={props.buttons}>
				{socialLinks.map((link) => (
					<BorderedButton
						href={link.link}
						target="_blank"
						key={link.key}
						responsive
					>
						<span>{link.title}</span>
					</BorderedButton>
				))}
			</Wrapper>
		);
	}
	return (
		<Wrapper direction={props.direction}>
			{socialLinks.map((link) => (
				<caption key={link.key}>
					<a href={link.link} target="_blank" rel="noreferrer">
						{link.title}
					</a>
				</caption>
			))}
		</Wrapper>
	);
};
