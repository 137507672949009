import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { ThemeProvider } from "styled-components";

import { AnimatedRoutes } from "./components/AnimatedRoutes";
import { NavMenu } from "./components/NavMenu";
import { useMediaQuery } from "react-responsive";
import { SEO } from "./seo";

export function App() {
	const theme = {
		primary: "#F5F2E5",
		secondary: "#23272D",
		isTabletOrMobile: useMediaQuery({
			query: "(max-width: 1224px)",
		}),
		divider: "4px solid #F5F2E5",
		largeBorderRadius: 8,
	};

	return (
		<div className="app">
			<Router>
				<SEO />
				<ThemeProvider theme={theme}>
					<NavMenu />
					<div>
						<AnimatedRoutes />
					</div>
				</ThemeProvider>
			</Router>
		</div>
	);
}

export default App;
