import sanityClient from "../client";
import imageUrlBuilder from "@sanity/image-url";

export async function getAllProjectDataFromSanity() {
	try {
		const data = await sanityClient.fetch(`*[_type == "projects"]`);
		return data.sort((a, b) => (a.dateStart < b.dateStart ? 1 : -1));
	} catch (message) {
		return console.error(message);
	}
}

export async function getSingleProjectDataBySlug(slug) {
	try {
		const data = await sanityClient.fetch(`*[slug.current == $slug]`, {
			slug,
		});
		return data[0];
	} catch (message) {
		return console.error(message);
	}
}

export async function getSingleProjectDataById(id) {
	try {
		const data = await sanityClient.fetch(`*[_id == $id]`, { id });
		return data[0];
	} catch (message) {
		return console.error(message);
	}
}

export function getImgUrl(source) {
	return imageUrlBuilder(sanityClient).image(source);
}

// export function getSiteSeoFromSanity() {
// 	return sanityClient
// 		.fetch(`*[_id == 'siteSeo']`)
// 		.then((data) => data)
// 		.catch(console.error);
// }

export async function getDataFromSanityById(id) {
	try {
		const data = await sanityClient.fetch(`*[_id == '${id}']`);
		return data;
	} catch (e) {
		return console.error(e);
	}
}
