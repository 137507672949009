import React from "react";
import { Route, Routes, useLocation } from "react-router-dom";

import Home from "../pages/Home";
import SingleProject from "../pages/SingleProject";
import { Links } from "../pages/Links";
import { NotFound } from "../pages/NotFound";

import ScrollToTop from "./ScrollToTop";
import "../global.css";

import { AnimatePresence } from "framer-motion";

const routes = [
	{ path: "/", name: "Home", Component: Home },
	{ path: "/:slug", name: "", Component: SingleProject },
	{ path: "/links", name: "Links", Component: Links },
];

export function AnimatedRoutes() {
	const location = useLocation();

	return (
		<AnimatePresence>
			<ScrollToTop />
			<Routes location={location} key={location.pathname}>
				{routes.map(({ path, Component }) => {
					return (
						<Route
							key={path}
							exact
							path={path}
							element={<Component />}
						/>
					);
				})}
				<Route path="*" element={<NotFound />} />
			</Routes>
		</AnimatePresence>
	);
}
