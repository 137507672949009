import React from "react";
import notFound from "../assets/images/404.png";
import styled from "styled-components";

import "lazysizes";
import { Link } from "react-router-dom";

const Wrapper = styled.div`
	align-items: center;
	display: flex;
	justify-content: center;
	position: relative;
	padding: 0px 30px 30px 30px;
	height: 80vh;
	.content {
		width: ${(props) => (props.theme.isTabletOrMobile ? "90vw" : "30vw")};
		height: 100%;
		align-items: center;
		display: flex;
		flex-direction: column;
		justify-content: center;
		img {
			width: 100%;
		}
	}
`;

export const NotFound = () => {
	return (
		<React.Fragment>
			<Wrapper className="links-page-wrapper">
				<div className="content">
					<Link to={"/"}>
						<img src={notFound} alt="not found" />
					</Link>
				</div>
			</Wrapper>
		</React.Fragment>
	);
};
