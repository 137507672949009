import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { getDataFromSanityById } from "./utils/getDataFromSanity";

export const SEO = ({ title, meta }) => {
	const [data, setData] = useState({});
	useEffect(() => {
		getDataFromSanityById("siteSeo").then((data) => setData(data[0]));
	}, []);
	if (!data) {
		throw new Error(
			'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
		);
	}

	const { description, keywords, siteTitle } = data;
	return (
		<Helmet
			title={title}
			titleTemplate={`%s ✦ ${siteTitle}`}
			defaultTitle={siteTitle}
			meta={[
				{
					name: "description",
					content: meta ?? description,
				},
				{
					property: "og:title",
					content: siteTitle,
				},
				{
					property: "og:description",
					content: meta ?? description,
				},
				{
					property: "og:type",
					content: "website",
				},
				{
					name: "twitter:card",
					content: "summary",
				},
				{
					name: "twitter:creator",
					content: siteTitle,
				},
				{
					name: "twitter:title",
					content: siteTitle,
				},
				{
					name: "twitter:description",
					content: meta ?? description,
				},
			]
				.concat(
					keywords && keywords.length > 0
						? {
								name: "keywords",
								content: keywords.join(", "),
						  }
						: []
				)
				.concat(meta)}
		>
			<script
				async
				defer
				src="https://analytics.umami.is/script.js"
				data-website-id="fa0eef27-922e-4e7f-89eb-8e6abc07fa52"
			></script>
		</Helmet>
	);
};

SEO.defaultProps = {
	lang: "en",
	meta: [],
	keywords: [],
};

SEO.propTypes = {
	meta: PropTypes.array,
	title: PropTypes.string,
};
