import React, { useEffect, useState } from "react";
import { PortableText } from "@portabletext/react";
import { useParams } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import {
	getSingleProjectDataBySlug,
	getImgUrl,
	getSingleProjectDataById,
} from "../utils/getDataFromSanity";
import { SEO } from "../seo";
import { projectPageComponents } from "./constants";
import styled from "styled-components";
import moment from "moment";
import { Link } from "react-router-dom";
import { Arrow } from "../components/Arrow";
import { SmartrrSpinner } from "../assets/images/SmartrrSpinner";

const ProjectWrapper = styled.section.attrs((props) => ({
	className: "project-wrapper",
}))`
	margin-top: 35px;
	position: relative;
	h1 {
		line-height: 0.85em;
	}

	h4 {
		font-size: 20px;
		line-height: 24px;
		text-transform: uppercase;
		font-weight: 600;
		margin-bottom: 10px;
	}
	& p {
		font-weight: 400;
		font-size: 20px;
		line-height: 24px;
		letter-spacing: 0;
		text-transform: none;
	}
	.smartrr-spinner {
		position: absolute;
		margin: 10px;
		margin-top: -60px;
		right: 20px;
		animation: spinner 20s linear infinite;
		@keyframes spinner {
			0% {
				transform: rotate(0deg);
			}
			100% {
				transform: rotate(360deg);
			}
		}
	}

	& .intro-wrapper {
		margin: 40px 0
			${(props) => (props.theme.isTabletOrMobile ? "100px" : "250px")};
		display: flex;
		justify-content: space-between;
		flex-direction: ${(props) =>
			props.theme.isTabletOrMobile ? "column" : "row"};
		position: relative;

		.left-side {
			width: ${(props) =>
				props.theme.isTabletOrMobile ? "100%" : "30vw"};
			margin-bottom: 40px;
			& p {
				font-weight: 400;
				font-size: 14px;
				line-height: 18px;
				letter-spacing: 0;
				text-transform: none;
			}
		}
		.right-side {
			flex-direction: column;
			justify-content: center;
			display: flex;
			margin-right: ${(props) =>
				props.theme.isTabletOrMobile ? "0" : "25vw"};
			width: ${(props) =>
				props.theme.isTabletOrMobile ? "100%" : "35vw"};
			& p {
				font-size: 18px;
				line-height: 20px;
			}
		}
	}

	& .project-hero-wrapper {
		padding-bottom: ${(props) =>
			props.theme.isTabletOrMobile ? "0" : "40px"};
		border-bottom: 4px solid ${(props) => props.theme.primary};
	}
	& .hero-wrapper {
		position: relative;
		overflow: hidden;
		margin-bottom: 20px;
		height: ${(props) =>
			props.theme.isTabletOrMobile ? "270px" : "600px"};

		& .full-width-img {
			height: ${(props) =>
				props.theme.isTabletOrMobile ? "270px" : "800px"};
			width: 100%;
			background-repeat: no-repeat;
			background-size: ${(props) =>
				props.theme.isTabletOrMobile ? "contain" : "cover"};
			position: absolute;
			transform: translateZ(0);
			left: 0;
			background-size: cover;
			background-position: center center;
		}
	}
	.side-by-side-imgs-wrapper {
		display: flex;
		justify-content: center;
		& .side-by-side-imgs {
			display: flex;
			flex-direction: ${(props) =>
				props.theme.isTabletOrMobile ? "column" : "row"};
			justify-content: space-between;
			gap: 20px;
			margin-bottom: 50px;
			max-width: 1000px;
			width: 100%;
			& .img-wrapper {
				display: flex;
				flex-direction: column;

				width: ${(props) =>
					props.theme.isTabletOrMobile ? "100%" : "50%"};
			}
		}
	}

	& .center-text {
		border-bottom: ${(props) => props.theme.divider};
		border-top: ${(props) => props.theme.divider};
		position: relative;
		background-color: #1d1f24;
		padding: 40px;
		margin: 40px 0;
	}

	& .impact-section-wrapper {
		background-color: #1d1f24;
		padding: 40px;
		margin-bottom: 50px;
		.impact-section-body {
			display: grid;
			grid-template-columns: 1fr ${(props) =>
					props.theme.isTabletOrMobile ? "" : "1fr"};
			grid-row-gap: 16px;
			grid-column-gap: 10px;
			& .order-item {
				display: flex;
				flex-direction: row;
				& .ordered-num {
					color: #a0a09f;
					margin-right: 10px;
				}
				p {
					font-size: 18px;
					line-height: 20px;
					b {
						font-weight: 700;
					}
				}
			}
		}
	}
	.caption {
		max-width: 600px;
		padding: 1em 0px 0.4em;
		p {
			color: #a0a09f;
			font-size: 16px;
			text-transform: none;
		}
	}
	.full-width-wrapper {
		margin-bottom: 50px;

		display: flex;
		justify-content: center;
		.full-width-inner {
			max-width: 1000px;
			img {
				width: 100%;
				height: auto;
			}
		}
	}
	.quote-wrapper {
		padding: 4em 0;
		max-width: 650px;
		display: flex;
		margin: 0 auto;
		blockquote {
			font-size: 30px;
			margin: 0 0 1.4em;
		}
	}
`;

const ProjectAttributeWrapper = styled.div.attrs((props) => ({
	className: "project-attribute-wrapper",
}))`
	&:not(:first-child) {
		margin-top: 7px;
	}
`;

const ClosingSection = styled.div.attrs((props) => ({}))`
	display: flex;
	flex-direction: column;
	border-top: 4px solid ${(props) => props.theme.primary};
	margin-top: ${(props) => (props.theme.isTabletOrMobile ? "25px" : "50px")};

	& .next-project-section {
		flex-direction: ${(props) =>
			props.theme.isTabletOrMobile ? "column" : "row"};
		gap: 20px;
		display: flex;
		padding: 40px 0;
		& .next-project-image-wrapper {
			order: ${(props) => (props.theme.isTabletOrMobile ? 2 : 1)};
			width: 100%;
			a {
				text-decoration: none;
			}
			.next-project {
				/* width: ${(props) =>
					props.theme.isTabletOrMobile ? "100%" : "50vw"}; */
				height: ${(props) =>
					props.theme.isTabletOrMobile ? "200px" : "390px"};
				max-height: 390px;
				position: relative;
				object-fit: cover;
				background-size: cover;
				background-position: center center;
				display: flex;
				overflow: hidden;
				background-repeat: no-repeat;

				.overlay-text {
					display: flex;
					align-items: end;
					align-content: space-between;
					justify-content: end;
					flex-wrap: wrap;
					color: ${(props) => props.theme.primary};
					z-index: 2;
					padding: 15px;
					width: 100%;
					:hover div:first-child:before {
						width: 100%;
					}
					& div:first-child {
						width: max-content;
						display: flex;
						justify-content: end;
						position: relative;
						&:before {
							content: "";
							position: absolute;
							height: 4px;
							bottom: ${(props) =>
								props.theme.isTabletOrMobile ? "7px" : "20px"};
							background-color: ${(props) => props.theme.primary};
							left: 0px;
							width: 0px;
							transition: 0.9s cubic-bezier(0.075, 0.82, 0.165, 1);
						}
					}
					& div:nth-child(2) {
						width: 100%;
						display: flex;
						justify-content: start;
						h3 {
							line-height: 0.85em;
						}
					}
				}
			}
		}
		& .closing-wrapper {
			width: ${(props) =>
				props.theme.isTabletOrMobile ? "100%" : "70vw"};
			order: ${(props) => (props.theme.isTabletOrMobile ? 1 : 2)};
			padding-right: ${(props) =>
				props.theme.isTabletOrMobile ? "0" : "10em"};
			p {
				font-size: 18px;
				line-height: 20px;
			}

			& .bordered-button {
				padding: 4px 10px 0px 10px;
				line-height: 30px;
				font-size: 20px;
			}
		}
	}
	& .back-to-projects {
		border-top: 4px solid ${(props) => props.theme.primary};
		border-bottom: 4px solid ${(props) => props.theme.primary};
		display: flex;
		flex-direction: row;
		justify-content: end;
		text-decoration: none;
		color: ${(props) => props.theme.primary};
		align-items: center;
		&:hover svg {
			margin-right: 10px;
		}
		svg {
			width: ${(props) =>
				props.theme.isTabletOrMobile ? "10vw" : "5vw"};
			height: ${(props) =>
				props.theme.isTabletOrMobile ? "7vw" : "4vw"};
			margin-bottom: ${(props) =>
				props.theme.isTabletOrMobile ? "0" : "8px"};
			transition: 0.9s cubic-bezier(0.075, 0.82, 0.165, 1);
		}

		h3 {
			padding-top: 13px;
			white-space: nowrap;
		}
	}
`;

export default function SingleProject() {
	const [projectData, setProjectData] = useState();
	const [nextProjectData, setNextProjectData] = useState();
	const isTabletOrMobile = useMediaQuery({
		query: "(max-width: 1224px)",
	});
	const { slug } = useParams();
	useEffect(() => {
		getSingleProjectDataBySlug(slug).then((data) => setProjectData(data));
	}, [slug]);
	useEffect(() => {
		if (projectData && projectData.nextProject) {
			getSingleProjectDataById(projectData.nextProject._ref).then(
				(data) => setNextProjectData(data)
			);
		}
	}, [projectData]);

	// Giving document selector one tick before deploying
	let parallax;
	setTimeout(() => {
		parallax = document.querySelectorAll(".parallax-img");
	}, 100);
	window.addEventListener("scroll", () => {
		if (parallax && parallax.length) {
			parallax.forEach((el, i) => {
				const offset = isTabletOrMobile ? 0 : 2000 * i;
				const dist =
					window.pageYOffset -
					el.getBoundingClientRect().top -
					offset;
				if (!isTabletOrMobile) {
					el.style.top = dist * 0.1 + "px";
				}
			});
		}
	});

	if (projectData) {
		const projectLength = () => {
			if (projectData.dateStart && projectData.dateEnd) {
				if (moment(projectData.dateStart).isSame(projectData.dateEnd)) {
					return `${moment(projectData.dateStart).format(
						"MMM YYYY"
					)} - Present`;
				} else if (
					moment(projectData.dateStart).year() ===
					moment(projectData.dateEnd).year()
				) {
					return `${moment(projectData.dateStart).format(
						"MMM"
					)} - ${moment(projectData.dateEnd).format("MMM YYYY")}`;
				} else {
					return `${moment(projectData.dateStart).format(
						"MMM YYYY"
					)} - ${moment(projectData.dateEnd).format("MMM YYYY")}`;
				}
			} else if (projectData.dateStart) {
				return moment(projectData.dateStart).format("MMM YYYY");
			} else {
				return;
			}
		};

		return (
			<React.Fragment>
				<ProjectWrapper>
					<SEO
						title={projectData.relatedOrg}
						meta={projectData.meta ?? ""}
					/>
					<div className="project-hero-wrapper">
						<h1>{projectData.projectName}</h1>
						<div className="hero-wrapper">
							<div
								className="full-width-img parallax-img"
								style={{
									backgroundImage: `url(${getImgUrl(
										projectData.mainImage
									).url()})`,
								}}
							/>
						</div>
					</div>
					{projectData.introText ? (
						<div className="intro-wrapper">
							<div className="left-side">
								<ProjectAttributeWrapper>
									{projectLength && <p>{projectLength()}</p>}
								</ProjectAttributeWrapper>
								{projectData.extraAttrs &&
								projectData.extraAttrs.length
									? projectData.extraAttrs.map((attr, i) => (
											<ProjectAttributeWrapper key={i}>
												<p className="atrr-desc">
													{attr.value}
												</p>
											</ProjectAttributeWrapper>
									  ))
									: null}
							</div>
							<div className="right-side">
								{/* <h4>Overview</h4> */}
								<p>{projectData.introText}</p>
							</div>
						</div>
					) : null}

					{projectData && (
						<React.Fragment>
							{projectData.slug.current.includes("smartrr") && (
								<div className="smartrr-spinner">
									<SmartrrSpinner />
								</div>
							)}
							<PortableText
								value={projectData.body}
								components={projectPageComponents}
							/>
						</React.Fragment>
					)}
					<ClosingSection>
						{nextProjectData && (
							<div className="next-project-section">
								<div className="next-project-image-wrapper">
									<Link
										to={
											"/work/" +
											nextProjectData.slug.current
										}
									>
										<div
											style={{
												backgroundImage: `url(${getImgUrl(
													nextProjectData.mainImage
												).url()})`,
											}}
											id={nextProjectData.slug.current}
											className={`lazyload next-project overlay`}
										>
											<div className="overlay-text">
												<div>
													<h3>NEXT</h3>
												</div>
												<div>
													<h3>
														{
															nextProjectData.projectName
														}
													</h3>
												</div>
											</div>
										</div>
									</Link>
								</div>
								{projectData && projectData.closingText && (
									<div className="closing-wrapper">
										<PortableText
											value={projectData.closingText}
											components={projectPageComponents}
										/>
									</div>
								)}
							</div>
						)}
						<Link to={"/work/"} className="back-to-projects">
							<Arrow />
							<h3>Back To All Work</h3>
						</Link>
					</ClosingSection>
				</ProjectWrapper>
			</React.Fragment>
		);
	}
}
