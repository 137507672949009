import React, { useState, useEffect } from "react";
import avatar from "../assets/images/character.png";
import styled from "styled-components";
import { delayClickHandler } from "../utils/helpers";
import "lazysizes";
import { getDataFromSanityById } from "../utils/getDataFromSanity";
import { BorderedButton } from "./constants";

const Wrapper = styled.div`
	align-items: center;
	display: flex;
	justify-content: center;
	position: relative;
	padding: 0px 30px 30px 30px;
	height: 80vh;
	.content {
		width: 333px;
		height: 100%;
		align-items: center;
		display: flex;
		flex-direction: column;
		justify-content: center;
		.hero-avatar {
			width: 150px;
			height: auto;
		}

		.button-wrapper {
			display: flex;
			flex-direction: column;
			justify-content: center;

			a {
				width: 262px;
				text-align: center;
				margin: 15px 0;
			}
		}
	}
`;

export const Links = () => {
	const [linkData, setLinkData] = useState({});

	useEffect(() => {
		getDataFromSanityById("link").then((data) => setLinkData(data[0]));
	}, []);

	if (!linkData) return;
	return (
		<React.Fragment>
			<Wrapper className="links-page-wrapper">
				<div className="content">
					<img
						className="hero-avatar lazyload"
						data-src={avatar}
						alt="Andrew Clark Avatar in circle"
					/>
					{linkData.linksList && (
						<div className="button-wrapper">
							{linkData.linksList.map((link) => (
								<BorderedButton
									target="_blank"
									onClick={() =>
										delayClickHandler(link.urlSrc, true)
									}
								>
									<span>{link.title}</span>
								</BorderedButton>
							))}
							<BorderedButton
								target="_blank"
								onClick={() => delayClickHandler("/", true)}
							>
								<span>Full Site</span>
							</BorderedButton>
						</div>
					)}
				</div>
			</Wrapper>
		</React.Fragment>
	);
};
