import React, { useEffect, useState } from "react";
import { FooterWrapper, projectPageComponents } from "../pages/constants";
import { BorderedButton } from "../pages/constants";
import { useMediaQuery } from "react-responsive";
import { getDataFromSanityById, getImgUrl } from "../utils/getDataFromSanity";
import { SocialList } from "./SocialList";
import { PortableText } from "@portabletext/react";
import { LinkOut } from "akar-icons";
import { scrollToTop } from "../utils/helpers";

export const Footer = (props) => {
	const [aboutData, setAboutData] = useState();
	const isTabletOrMobile = useMediaQuery({
		query: "(max-width: 1224px)",
	});

	useEffect(() => {
		getDataFromSanityById("about").then((data) => setAboutData(data[0]));
	}, []);

	if (!aboutData) return <React.Fragment />;

	const AwardLine = ({ awardSection }) => {
		const { awardList, org } = awardSection;
		return (
			<div className="award">
				<p>{org}</p>
				{awardList.map((award) => {
					if (!!award.urlSrc) {
						return (
							<React.Fragment>
								<a
									href={award.urlSrc}
									target="_blank"
									className="sub"
									rel="noreferrer"
								>
									{award.awardName}
									<LinkOut size={18} />
								</a>
							</React.Fragment>
						);
					} else {
						return <p className="sub">{award.awardName}</p>;
					}
				})}
			</div>
		);
	};

	return (
		<FooterWrapper>
			<div className="footer-upper">
				<h2 className="mob">
					Hello! <br />
					I'm Andrew <span className="w-100">✧</span>
				</h2>
				<div className="left-copy">
					<h2 className="desk float">Hello!</h2>
					<div className="body">
						<PortableText
							value={aboutData.about}
							components={projectPageComponents}
						/>
					</div>
					<div
						className="about-headshot mob"
						style={{
							backgroundImage: `url(${getImgUrl(
								aboutData.headshot.asset
							).url()})`,
						}}
						data-src={getImgUrl(aboutData.headshot.asset).url()}
						alt={aboutData.headshot.alt}
					/>
					<h3 className="icon w-100 ">❂</h3>
					<BorderedButton
						small
						href={aboutData.resumeLink}
						target="_blank"
					>
						<span>Resume Download</span>
					</BorderedButton>
				</div>
				<div className="">
					<div
						className="about-headshot desk"
						style={{
							backgroundImage: `url(${getImgUrl(
								aboutData.headshot.asset
							).url()})`,
						}}
						data-src={getImgUrl(aboutData.headshot.asset).url()}
						alt={aboutData.headshot.alt}
					/>
					<SocialList icons />
				</div>
				<div className="right-copy">
					<h2 className="desk float">
						I'm Andrew<span className="w-100">✧</span>
					</h2>
					<div className="awards-wrapper">
						{!!aboutData.footerAwardsList &&
							aboutData.footerAwardsList.map((awardSection) => (
								<AwardLine awardSection={awardSection} />
							))}
					</div>
				</div>
			</div>
			<div className="footer-lower">
				<div onClick={scrollToTop}>
					<BorderedButton
						className={
							isTabletOrMobile ? "name-tag-short" : "name-tag"
						}
						overlap
					>
						<span>
							©
							{isTabletOrMobile ? (
								<span>AC</span>
							) : (
								<span>
									A<span id="n">n</span>drew Clark
								</span>
							)}{" "}
							{new Date().getFullYear()}
						</span>
					</BorderedButton>
				</div>
			</div>
		</FooterWrapper>
	);
};
