import React, { useMemo } from "react";
import { ScrollingMarqueeWrapper } from "../pages/constants";

const ARRAY_LENGTH = 30;

const icons = ["❂", "✷", "✦", "✧", "✺", "✪"];
const skills = [
	"Typescript",
	"Responsive Web Design",
	"Node.JS",
	"PHP",
	"SEO",
	"Shopify",
	"*Insert Buzzword here*",
	"Making Coffee",
	"React",
	"TypeScript",
	"Figma",
	"MySql",
	"My mom says I'm handsome",
	"C++",
	"Redux",
	"Notion",
	"Node.js",
	"Jest",
	"Sanity",
	"Adobe Suite",
];

const getRandomSkill = () => {
	const randIndex = Math.floor(Math.random() * skills.length);
	return skills[randIndex];
};
const getRandomIcon = () => {
	const randIndex = Math.floor(Math.random() * icons.length);
	return icons[randIndex];
};

export const ScrollingMarquee = () => {
	function generateArray() {
		let arr = [];

		const isDuplicate = (index, skill, icon) => {
			const checkIndex = index - 1;
			return index !== 0
				? arr[checkIndex].skill === skill ||
						arr[checkIndex].icon === icon
				: false;
		};

		[...Array(ARRAY_LENGTH)].forEach((_, i) => {
			function addItemToArr() {
				const icon = getRandomIcon();
				const skill = getRandomSkill();

				if (!isDuplicate(i, skill, icon)) {
					arr.push({ icon, skill });
				} else {
					addItemToArr();
				}
				return;
			}

			addItemToArr();
		});

		return arr;
	}

	const topArr = useMemo(() => generateArray(), []);
	const bottomArr = useMemo(() => generateArray(), []);

	if (!topArr.length || !bottomArr.length) return <React.Fragment />;

	const MarqueeEntry = ({ icon, skill }) => (
		<React.Fragment>
			<h3 className="w-100">{icon}</h3>
			<h3>{skill}</h3>
		</React.Fragment>
	);

	return (
		<ScrollingMarqueeWrapper>
			<div className="marquee-top">
				{topArr.map(({ icon, skill }, index) => (
					<MarqueeEntry key={index} icon={icon} skill={skill} />
				))}
			</div>
			<div className="divider"></div>
			<div className="marquee-bottom">
				{bottomArr.map(({ icon, skill }, index) => (
					<MarqueeEntry key={index} icon={icon} skill={skill} />
				))}
			</div>
		</ScrollingMarqueeWrapper>
	);
};
