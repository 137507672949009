import React from "react";

export const Arrow = () => {
	return (
		<svg
			width="29"
			height="37"
			viewBox="0 0 29 37"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g clip-path="url(#clip0_285_346)">
				<path
					d="M26.5898 19.0801L5.03446 19.0801"
					stroke="#F5F2E5"
					stroke-width="4"
					stroke-linecap="round"
					stroke-linejoin="round"
				/>
				<path
					d="M15.8125 29.8574L5.03481 19.0797L15.8125 8.30204"
					stroke="#F5F2E5"
					stroke-width="4"
					stroke-linecap="round"
					stroke-linejoin="round"
				/>
			</g>
			<defs>
				<clipPath id="clip0_285_346">
					<rect
						width="28"
						height="36"
						fill="white"
						transform="translate(28.9062 36.3301) rotate(180)"
					/>
				</clipPath>
			</defs>
		</svg>
	);
};
