import React from "react";
import styled from "styled-components";
import { PortableText } from "@portabletext/react";
import { getImgUrl } from "../utils/getDataFromSanity";
import { CustomGallery } from "./CustomGallery";
import "lazysizes";

export const BorderedButton = styled.a.attrs((props) => ({
	className: "bordered-button",
}))`
	position: relative;
	display: block;
	border: ${(props) =>
			props.responsive && props.theme.isTabletOrMobile ? "2px" : "4px"}
		solid ${(props) => props.theme.primary};
	border-radius: ${(props) =>
		props.small ? "4" : props.theme.largeBorderRadius}px;
	padding: ${(props) =>
		props.small
			? "11px 15px 6px 15px"
			: props.responsive && props.theme.isTabletOrMobile
			? "3px 10px 0px 10px"
			: props.largeText && !props.theme.isTabletOrMobile
			? "44px 40px 24px 40px"
			: "12px 20px 2px 20px"};
	font-size: ${(props) =>
		props.small
			? "18px"
			: props.responsive
			? "calc(100% + 1.6vw)"
			: props.largeText
			? "calc(100% + 4.7vw)"
			: "40px"};
	background-color: ${(props) => props.theme.secondary};

	line-height: ${(props) => (props.small ? 18 : 37)}px;
	font-weight: 700;
	overflow: hidden;
	text-transform: uppercase;
	text-decoration: none;
	width: max-content;
	& span {
		mix-blend-mode: ${(props) => !props.overlap && "difference"};
		color: #fff;
	}
	&:before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: ${(props) => props.theme.primary};
		transform: translate3d(0, 100%, 0);
		transition: transform 0.3s cubic-bezier(0.7, 0, 0.2, 1);
	}
	&:hover::before {
		${(props) =>
			!props.overlap &&
			`transform: translate3d(0, 0, 0);
		transition: transform 0.3s cubic-bezier(0.7, 0, 0.2, 1);`}
	}
	&:hover {
		cursor: pointer;
	}
	/* Styles for name tag */
	&.name-tag-short {
		margin: 0 auto;
		letter-spacing: -4px;
		padding: 12px 10px 2px 10px;
	}
	&.name-tag {
		letter-spacing: -4px;
		& #n {
			letter-spacing: -11px;
		}
	}
`;

export const HeroWrapper = styled.div.attrs((props) => ({
	className: "hero-wrapper",
}))`
	padding-top: ${(props) => (props.theme.isTabletOrMobile ? "2em" : "4em")};
	display: flex;
	flex-direction: column;
	position: relative;
	border-bottom: ${(props) => props.theme.divider};
	margin-bottom: 40px;
	h1 {
		line-height: 0.85em;
	}

	& .hero-copy-upper {
		width: 80vw;
	}
	.pushed-subtitle {
		position: absolute;
		bottom: 0;
		right: 0;
		display: flex;
		justify-content: end;
	}
	& .hero-copy-split {
		display: flex;
		flex-direction: ${(props) =>
			props.theme.isTabletOrMobile ? "column" : "row"};
		sub {
			margin: ${(props) =>
				props.theme.isTabletOrMobile ? "1em 0" : "2em 1em"};
		}
		& :first-child {
			order: ${(props) => (props.theme.isTabletOrMobile ? "2" : "1")};
		}
		& :nth-child(2) {
			/* text-indent: ${(props) =>
				props.theme.isTabletOrMobile ? "0" : "0.55em"}; */
			order: ${(props) => (props.theme.isTabletOrMobile ? "1" : "2")};
		}
	}

	& img.hero-avatar {
		position: absolute;
		right: -24px;
		animation: bounce 7s ease-in-out;
		bottom: 30px;
		animation-iteration-count: infinite;
		z-index: 3;
		max-height: ${(props) =>
			props.theme.isTabletOrMobile
				? "calc(63% + 0.6vw)"
				: "calc(87% + 0.6vw)"};
	}
	& .social-list {
		${(props) =>
			props.theme.isTabletOrMobile
				? `
			margin-top:45px;
		`
				: `
		position: absolute;
		right: 45px;
		bottom: 0px;	
		`}
	}

	.header {
		h1 {
			line-height: 0.85em;
			&:first-child {
				text-indent: 1.75em;
			}
		}
	}

	@keyframes bounce {
		0% {
			bottom: ${(props) =>
				props.theme.isTabletOrMobile ? "70px" : "100px"};
		}
		50% {
			bottom: ${(props) =>
				props.theme.isTabletOrMobile ? "30px" : "70px"};
		}
		100% {
			bottom: ${(props) =>
				props.theme.isTabletOrMobile ? "70px" : "100px"};
		}
	}
`;

export const AboutWrapper = styled.div.attrs((props) => ({
	className: "about-wrapper",
}))`
	margin: ${(props) =>
		props.theme.isTabletOrMobile ? `20px 0` : "180px 0 100px 0"};
	display: flex;
	justify-content: end;
	gap: ${(props) => (props.theme.isTabletOrMobile ? `20px` : "90px")};
	flex-direction: ${(props) =>
		props.theme.isTabletOrMobile ? "column" : "row"};

	& .left-copy {
		order: ${(props) => (props.theme.isTabletOrMobile ? `1` : "2")};
		width: ${(props) => (props.theme.isTabletOrMobile ? "100%" : "52vw")};
		display: flex;
		flex-direction: column;
		justify-content: center;

		& .bordered-button {
			margin-top: 20px;
		}
		& p {
			padding: 10px;
			text-transform: none;
			font-weight: 500;
			text-wrap: balance;
			b,
			a {
				font-weight: 900;
			}
			width: ${(props) =>
				props.theme.isTabletOrMobile ? "100%" : "360px"};
		}
		a {
			color: ${(props) => props.theme.primary};
		}
	}
	& .right-images {
		order: ${(props) => (props.theme.isTabletOrMobile ? `2` : "1")};
		display: flex;
		flex-direction: row;
		width: 100%;
		gap: 20px;
		@media (min-width: 110em) {
			width: 25vw;
		}
		${(props) => props.theme.isTabletOrMobile && `margin-top:25px;`};
		& .img {
			background-color: grey;
			border-radius: 4px;
			background-repeat: no-repeat;
			background-position: center center;
			background-size: cover;
			width: 300px;
			height: 300px;
			box-shadow: -6px 0px 20px 0px rgba(0, 0, 0, 0.25);
			max-width: ${(props) =>
				props.theme.isTabletOrMobile ? "200px" : "22vw;"};
			max-height: ${(props) =>
				props.theme.isTabletOrMobile ? "200px" : "22vw;"};
			&:not(:nth-child(2)) {
				align-self: ${(props) =>
					props.theme.isTabletOrMobile ? "start" : "flex-end"};
			}
			&:nth-child(2) {
				margin: ${(props) =>
						props.theme.isTabletOrMobile ? "40px" : "-100px"}
					0;
				margin-right: 0;
			}
			&:nth-child(3) {
				margin-bottom: ${(props) =>
					props.theme.isTabletOrMobile ? "-30px" : "-70px"};
			}
		}
	}
`;

export const SelectedWorks = styled.div.attrs((props) => ({
	className: "selected-works-wrapper",
}))`
	margin-top: 75px;
	.header {
		border-bottom: ${(props) => props.theme.divider};

		margin-bottom: 30px;
		h1 {
			line-height: 0.85em;
			&:first-child {
				text-indent: 1.75em;
			}
		}
	}
	& .selected-works-inner {
		& .project-fw {
			display: block;
			width: 100%;
			height: 100%;
			max-height: 100%;
			box-sizing: border-box;

			&:not(:first-child) {
				padding-top: 20px;
			}
			& a {
				text-decoration: none;
				& [class*="project-img"] {
					& > * {
						z-index: 100;
					}
					width: 100%;
					height: ${(props) =>
						props.theme.isTabletOrMobile ? "300px" : "700px"};
					max-height: 768px;
					position: relative;
					object-fit: cover;
					background-size: cover;
					background-attachment: fixed;
					display: flex;
					align-items: center;
					overflow: hidden;
					background-repeat: no-repeat;
					background-position: center;
					h1 {
						position: relative;
						margin: 0px 40px;
						color: ${(props) => props.theme.primary};
						text-decoration: none;
						white-space: nowrap;
						transition: none;
					}
				}
			}
		}
	}
`;

export const CenteredButton = styled.div.attrs((props) => ({
	className: "centered-button",
}))`
	padding-top: 95px;
	display: flex;
	align-items: center;
	justify-content: center;

	&.bordered-button {
		margin: 0 auto;
	}
`;

export const ScrollingMarqueeWrapper = styled.div.attrs((props) => ({
	className: "scrolling-marquee",
}))`
	border-top: 4px solid ${(props) => props.theme.primary};
	border-bottom: 4px solid ${(props) => props.theme.primary};

	width: 100%;
	overflow: hidden;
	margin: ${(props) => (props.theme.isTabletOrMobile ? "0" : "7vh")} 0;

	& [class*="marquee"] {
		display: flex;
		flex-direction: row;
		position: relative;
		width: max-content;

		margin-top: ${(props) =>
			props.theme.isTabletOrMobile ? "13px" : "30px"};

		& h3 {
			padding-left: ${(props) =>
				props.theme.isTabletOrMobile ? "25px" : "50px"};
			white-space: nowrap;
			line-height: 104%;
		}
	}
	& .divider {
		margin: 0px;
		border-bottom: 4px solid ${(props) => props.theme.primary};
		height: 4px;
		width: 70vw;
		margin: 0 auto;
	}
	& [class*="top"] {
		will-change: transform;
		animation: marquee 40s linear infinite reverse;
	}
	& [class*="bottom"] {
		will-change: transform;
		animation: marquee 40s linear infinite;
	}

	@keyframes marquee {
		from {
			transform: translateX(0);
		}
		to {
			transform: translateX(-50%);
		}
	}
`;

export const AllWorkWrapper = styled.div.attrs((props) => ({
	className: "all-work-wrapper",
}))`
	display: flex;
	flex-direction: column;
	gap: 60px;
	border-bottom: ${(props) =>
		props.theme.isTabletOrMobile ? props.theme.divider : "none"};
	& > * {
		text-decoration: none;
		color: ${(props) => props.theme.primary};
	}
	& .project-fw {
		display: flex;
		height: ${(props) =>
			props.theme.isTabletOrMobile ? "max-content" : "445px"};
		flex-direction: ${(props) =>
			props.theme.isTabletOrMobile ? "column" : "row"};
		align-items: ${(props) =>
			props.theme.isTabletOrMobile ? "start" : "end"};
		border-bottom: ${(props) =>
			props.theme.isTabletOrMobile
				? "none"
				: `4px solid ${props.theme.primary}`};
		justify-content: ${(props) =>
			props.theme.isTabletOrMobile ? "end" : "space-between"};
		transition: 0.9s cubic-bezier(0.075, 0.82, 0.165, 1);

		&:hover .name-wrapper p:before {
			width: 100%;
		}
		p {
			width: 90%;
		}

		&.text-left {
			text-align: start;
		}
		&.text-right {
			& .name-wrapper {
				display: flex;
				flex-direction: column;
				align-items: end;
				p,
				h3 {
					text-align: end;
				}
			}
		}
		${(props) =>
			!props.theme.isTabletOrMobile &&
			`
			&.text-left {
				& .name-wrapper {
					order: 1;
					
				}
				& .image-wrapper {
					order: 2;
				}
			}
			&.text-right {
				& .name-wrapper {
					order: 2;
				}
				& .image-wrapper {
					order: 1;
				}
			}
		`}

		& .name-wrapper {
			position: relative;
			padding-bottom: ${(props) =>
				props.theme.isTabletOrMobile ? "20px" : "0"};
			.attrs-wrapper {
				min-height: 40px;
				& sub {
					border: 2px solid ${(props) => props.theme.primary};
					border-radius: ${(props) =>
						props.theme.largeBorderRadius}px;
					padding: 10px 10px 5px 10px;
					&:not(:first-child) {
						margin-left: 7px;
					}
				}
			}

			h3 {
				margin-top: ${(props) =>
					props.theme.isTabletOrMobile ? "0" : "20px"};
				text-decoration: none;
				line-height: 0.85em;
				width: 100%;
				font-weight: 600;
			}
		}
		& .image-wrapper {
			padding: ${(props) =>
				props.theme.isTabletOrMobile ? "0px 0px 1em 0px" : "1em 0"};
			width: ${(props) =>
				props.theme.isTabletOrMobile ? "100%" : "55vw"};
			transition: 0.9s cubic-bezier(0.075, 0.82, 0.165, 1);
			& .project-img {
				height: ${(props) =>
					props.theme.isTabletOrMobile ? "240px" : "412px"};
				width: ${(props) =>
					props.theme.isTabletOrMobile ? "100%" : "55vw"};
				position: relative;
				object-fit: cover;
				background-size: cover;
				background-position: center center;
			}
		}

		&:hover .image-wrapper {
			padding-bottom: ${(props) =>
				props.theme.isTabletOrMobile
					? "1em"
					: "2em"}; // hover only for desktop
		}
	}
`;

export const FooterWrapper = styled.div.attrs((props) => ({
	className: "footer-wrapper",
}))`
	height: max-content;
	width: 100%;
	position: relative;
	margin-top: ${(props) =>
		props.theme.isTabletOrMobile ? "100px" : "200px"};
	display: flex;
	flex-direction: column;
	align-items: center;

	.mob:is(h2, h3) {
		line-height: calc(100% + -1.5vw);
	}
	h2.mob {
		margin-bottom: 20px;
	}
	h3.icon {
		font-size: 60px;
		animation: spinner 10s linear infinite;
		align-self: center;
		width: max-content;
		height: 50px;
		line-height: 1;
	}

	& .footer-upper {
		grid-template-columns: ${(props) =>
			props.theme.isTabletOrMobile ? "1fr" : "0.8fr 0.8fr 1fr"};

		display: inline-grid;
		justify-content: center;
		width: ${(props) => (props.theme.isTabletOrMobile ? "100%" : "90%")};
	}
	& .left-copy,
	& .right-copy {
		position: relative;
	}

	& .float {
		position: absolute;
		white-space: nowrap;
	}

	& .awards-wrapper {
		gap: ${(props) => (props.theme.isTabletOrMobile ? "20px" : "20px")};
		display: flex;
		flex-direction: column;
		& p,
		& a {
			display: block;
			border: none;
			padding: 0;
			color: inherit;
			text-decoration: none;
			font-size: 18px;
			& svg {
				padding-left: 5px;
			}
		}
	}

	& .left-copy {
		display: flex;
		justify-content: ${(props) =>
			props.theme.isTabletOrMobile ? "start" : "end"};
		flex-direction: column;
		height: max-content;
		gap: 20px;
		& p {
			width: 90%;
		}
		& .bordered-button {
			align-self: center;
		}

		& .float {
			top: -110px;
			left: -8px;
		}
	}

	& .right-copy {
		margin-left: ${(props) =>
			props.theme.isTabletOrMobile ? "0" : "15px"};
		display: flex;
		height: 100%;
		justify-content: ${(props) =>
			props.theme.isTabletOrMobile ? "start" : "space-between"};
		flex-direction: column;

		& .float {
			left: -230px;
			top: -35px;
		}
		& .awards-wrapper {
			margin-top: ${(props) =>
				props.theme.isTabletOrMobile ? "40px" : "70px"};
		}
	}

	& p {
		border-left: 4px solid ${(props) => props.theme.primary};
		padding: 0 0 0 10px;
		text-transform: none;
		text-wrap: balance;
		font-size: 16px;
		line-height: 19px;
		width: ${(props) => (props.theme.isTabletOrMobile ? "100%" : "90%")};
		b,
		a {
			font-weight: 900;
		}
	}
	a {
		color: ${(props) => props.theme.primary};
	}

	& .footer-social-list {
		width: 100%;
		display: flex;
		padding-left: 20px;
		/* justify-content: center; */

		& .social-list {
			width: ${(props) =>
				props.theme.isTabletOrMobile ? "100%" : "80%"};
			display: flex;
			/* justify-content: space-between; */
			text-align: center;
		}
	}

	& .about-headshot {
		width: ${(props) => (props.theme.isTabletOrMobile ? "70%" : "31vw")};
		height: ${(props) => (props.theme.isTabletOrMobile ? "300px" : "31vw")};
		border-radius: 4px;
		background-repeat: no-repeat;
		background-position: center center;
		background-size: cover;
		margin: 0 ${(props) => (props.theme.isTabletOrMobile ? "0" : "auto")};
		align-self: center;
	}

	& .footer-lower {
		width: 100%;
		margin-top: 70px;
		display: flex;
		align-items: baseline;
		justify-content: end;

		a {
			text-decoration: none;
		}
	}
`;

export const projectPageComponents = {
	types: {
		figure: (props) => {
			const { asset, alt, caption } = props.value;
			return (
				<div className="full-width-wrapper inner">
					<div className="full-width-inner">
						<img
							className="lazyload"
							data-src={getImgUrl(asset).url()}
							alt={alt}
						/>
						{caption ? (
							<div className="caption">
								<p
									dangerouslySetInnerHTML={{
										__html: caption,
									}}
								/>
							</div>
						) : null}
					</div>
				</div>
			);
		},
		duelImages: (props) => {
			const { leftImg, rightImg } = props.value;

			return (
				<div className="side-by-side-imgs-wrapper">
					<div className="side-by-side-imgs">
						{leftImg.asset && (
							<div className="img-wrapper">
								<img
									src={getImgUrl(leftImg.asset).url()}
									data-src={getImgUrl(leftImg.asset).url()}
									className="left-img lazyload parallax-img "
									alt={leftImg.alt}
								/>
								{leftImg.caption ? (
									<div className="caption">
										<p
											dangerouslySetInnerHTML={{
												__html: leftImg.caption,
											}}
										/>
									</div>
								) : null}
							</div>
						)}

						{rightImg.asset && (
							<div className="img-wrapper">
								<img
									src={getImgUrl(rightImg.asset).url()}
									className="right-img lazyload"
									alt={rightImg.alt}
								/>
								{rightImg.caption ? (
									<div className="caption">
										<p
											dangerouslySetInnerHTML={{
												__html: rightImg.caption,
											}}
										/>
									</div>
								) : null}
							</div>
						)}
					</div>
				</div>
			);
		},

		ctaButton: (props) => {
			const { text, url } = props.value;
			return (
				<div
					style={{
						padding: "1em 0",
						display: "flex",
						justifyContent: "center",
					}}
				>
					<BorderedButton responsive href={url}>
						<span>{text}</span>
					</BorderedButton>
				</div>
			);
		},
		centerText: (props) => {
			const val = props.value;
			return (
				<div className="center-text">
					<PortableText
						value={val.body}
						components={projectPageComponents}
					/>
				</div>
			);
		},
		gallery: (props) => {
			return <CustomGallery props={props} />;
		},
		impact: (props) => {
			const text = props.value.text;
			return (
				<div className="impact-section-wrapper">
					<h4>My impact</h4>
					<div className="impact-section-body">
						{text.map((val, i) => {
							return (
								<span className="order-item">
									<p className="ordered-num">{`0${i + 1}`}</p>
									<p
										dangerouslySetInnerHTML={{
											__html: val,
										}}
									/>
								</span>
							);
						})}
					</div>
				</div>
			);
		},
		blockQuote: (props) => {
			const text = props.value.blockQuote;
			const caption = props.value.caption;

			return (
				<div className="quote-wrapper">
					<blockquote>{text}</blockquote>
					{caption ? (
						<div className="caption">
							<p>{caption}</p>
						</div>
					) : null}
				</div>
			);
		},
	},
};
