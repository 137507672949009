import React from "react";

import "lazysizes";

import { HomepageHero } from "../components/HomepageHero";
import { StackImagesAbout } from "../components/StackImagesAbout";
import Projects from "../components/Projects";
import { Footer } from "../components/Footer";

export default function Home() {
	return (
		<React.Fragment>
			<HomepageHero />
			<StackImagesAbout />
			<Projects />
			<Footer />
		</React.Fragment>
	);
}
