import React from "react";
import { HeroWrapper } from "../pages/constants";
import { useMediaQuery } from "react-responsive";
import avatar from "../assets/images/ac-avatar.png";

export const HomepageHero = (props) => {
	const isTabletOrMobile = useMediaQuery({
		query: "(max-width: 1224px)",
	});
	return (
		<HeroWrapper>
			<div className="hero-copy-upper">
				<h1>designer &amp;</h1>
				<div className="hero-copy-split">
					<h1>
						Devel<span className="w-100">❂</span>per
					</h1>
					{!isTabletOrMobile && (
						<sub className="caption">
							Frontend Developer &amp;
							<br />
							Web Designer
						</sub>
					)}
				</div>
				<h1>
					with a love f<span className="w-100">✷</span>r Digital
				</h1>
			</div>
			<div className="hero-copy-split">
				<sub className="caption">
					✦ Based in
					<br />
					New Jersey
				</sub>
				<h1>experiences</h1>
			</div>
			<img
				className="hero-avatar lazyload"
				data-src={avatar}
				alt="Andrew Clark Avatar"
			/>
		</HeroWrapper>
	);
};
